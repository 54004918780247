<div class="sipd-container">

  <header class="sipd-header">
    <div class="mat-title m-0">
      {{title}}
    </div>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
    </div>

    <div class="actions">
      <div class="sync-status">
        <mat-icon class="connection-icon" *ngIf="alertsConnectionState.value===2">sync</mat-icon>
        <mat-icon class="connection-icon" *ngIf="alertsConnectionState.value===1">refresh</mat-icon>
        <mat-icon class="connection-icon" *ngIf="alertsConnectionState.value===0">sync_problem</mat-icon>
        <span class="connection-icon small">{{alertsConnectionState.text}}</span>
      </div>

      <button mat-icon-button (click)="onLocationManyClick()" matTooltip="Mostrar no Mapa os Alertas Selecionados"
        [disabled]="appAlertSelection.selected.length == 0 && simfAlertSelection.selected.length == 0">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button (click)="onCreateEventManyClick()" matTooltip="Criar novo Evento usando dados do primeito alerta selecionado,
                                                                         e vincular o novo Evento aos Alertas Selecionados"
        [disabled]="appAlertSelection.selected.length == 0 && simfAlertSelection.selected.length == 0" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_EVENT)'>
        <mat-icon class="material-icons-outlined">add_box</mat-icon>
      </button>
      <button mat-icon-button (click)="onLinkEventManyClick()" matTooltip="Vincular Evento aos Alertas Selecionados" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'
        [disabled]="appAlertSelection.selected.length == 0 && simfAlertSelection.selected.length == 0">
        <mat-icon class="material-icons-outlined">add_link</mat-icon>
      </button>
      <button mat-icon-button (click)="onUnLinkEventManyClick()" matTooltip="Desvincular Evento dos Alertas Selecionados" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'
        [disabled]="appAlertSelection.selected.length == 0 && simfAlertSelection.selected.length == 0">
        <mat-icon class="material-icons-outlined">link_off</mat-icon>
      </button>
      <button mat-icon-button (click)="onAcknowledgeManyClick()" matTooltip="Reconhecer Alertas Selecionados (que não foram reconhecidos ainda)"
        [disabled]="appAlertSelection.selected.length == 0 && simfAlertSelection.selected.length == 0" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'>
        <mat-icon class="material-icons-outlined">done</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Mostrar ou Esconder Detalhes do Alerta Clicado" (click)="onDetailsClick()">
        <mat-icon class="material-icons-outlined" *ngIf="showDetails">visibility_off</mat-icon>
        <mat-icon class="material-icons-outlined" *ngIf="!showDetails">visibility</mat-icon>
      </button>

      <button mat-icon-button color="primary"
        matTooltip="Mostrar Mensagens da Operação para o Alerta Selecionado
                    (Somente 1 alerta pode estar selecionado)"
        [disabled]="appAlertSelection.selected.length != 1"
        (click)="operationMessagesClicked(appAlertSelection.selected[0])">
        <mat-icon class="material-icons-outlined">forum</mat-icon>
      </button>
    </div>
  </header>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white d-flex">

    <as-split direction="vertical">
      <as-split-area [size]="getSizeAlerts()" [lockSize]="getLockSize()">
        <app-central-list #appAlertList [title]="appAlertsTitle" [displayedColumns]="appDisplayedColumns"
          [inMemoryDetailsAlert]="inMemoryDetailsAlert" [showDetails]="showDetails" [sourceApp]="true"
          (alertClicked)="alertClicked($event)" (locationClicked)="locationClicked($event)"
          (eventClicked)="eventClicked($event)" (acknowledgeClicked)="acknowledgeClicked($event)"
          (operationMessagesClicked)="operationMessagesClicked($event)" (operationClicked)="operationClicked($event)" 
          (dataSourceLoaded)="dataSourceLoaded($event)" (loggedUserPermission)="loggedUserPermission($event)"
          class="w-100 h-50 alert-list"></app-central-list>
      </as-split-area>

      <as-split-area  [size]="getSizeAlertsSimf()" [lockSize]="getLockSize()">
        <app-central-list #simfAlertList [title]="simfAlertsTitle" [displayedColumns]="simfDisplayedColumns"
          [inMemoryDetailsAlert]="inMemoryDetailsAlert" [showDetails]="showDetails" [sourceApp]="false"
          (alertClicked)="alertClicked($event)" (locationClicked)="locationClicked($event)"
          (eventClicked)="eventClicked($event)" (acknowledgeClicked)="acknowledgeClicked($event)" (dataSourceLoaded)="dataSourceLoaded($event)" (loggedUserPermission)="loggedUserPermission($event)"
          class="w-100 h-50 alert-list"></app-central-list>
      </as-split-area>
    </as-split>

    <mat-divider [vertical]="true"></mat-divider>

    <div class="side-panel sipd-container" *ngIf="showDetails">
      <div class="sipd-header">
        <div class="mat-title m-0">ID: {{inMemoryDetailsAlert?.identifier}}&nbsp; </div>
        <div class="fill-space"></div>
        <div class="actions">
          <button mat-raised-button (click)="onSaveDetailsClick()" [disabled]="!detailsChanged">Salvar</button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sipd-content p-2">
        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0">
          <div class=" my-3 py-2 px-4 d-block bg-shade-100 rounded form-spacer" fxFlex *ngIf='authorizationService.userHasPermission(permission.EDIT_ALERT)'>
            <mat-slide-toggle (click)="$event.stopPropagation()" color="accent"
              (change)="onDetailedAlertAcknowledgedClick()" [checked]="isDetailedAlertAcknowledged()">
              Reconhecido
            </mat-slide-toggle>
          </div>

          <mat-form-field appearance="outline" class="w-100 mr-2" *ngIf='authorizationService.userHasPermission(permission.EDIT_ALERT)'>
            <mat-label>Notas</mat-label>
            <textarea matInput [(ngModel)]="inMemoryDetailsAlert.observation" (keyup)="onChangedNotes()" rows="4"
              [readonly]="!inMemoryDetailsAlert.id"></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>Quem Reconheceu</mat-label>
            <input matInput name="who" placeholder="Quem Reconheceu"
              [value]="inMemoryDetailsAlert?.acknowledgmentAuthor ? inMemoryDetailsAlert?.acknowledgmentAuthor : ''"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>Data/hora Reconhecido</mat-label>
            <input matInput name="when" placeholder="Data/hora Reconhecido"
              [value]="(inMemoryDetailsAlert.acknowledgmentDate == 0) ? '' : inMemoryDetailsAlert?.acknowledgmentDate | date:'dd/MM/yyyy HH:mm'"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>Data/Hora Recebimento</mat-label>
            <input matInput name="level" placeholder="Nível"
              [value]="(inMemoryDetailsAlert?.createdAt == 0) ? '' : inMemoryDetailsAlert?.createdAt | date:'dd/MM/yyyy HH:mm'"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>Nível</mat-label>
            <input matInput name="level" placeholder="Nível"
              [value]="inMemoryDetailsAlert.alertPriority ? alertPriorityDescription[inMemoryDetailsAlert?.alertPriority] : ''"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>KM (Lat, Long)</mat-label>
            <input matInput name="km" placeholder="KM (Lat, Long)"
              [value]="inMemoryDetailsAlert?.location | PointLocationToString " readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>Data/hora da Localização</mat-label>
            <input matInput name="locationTimestamp" placeholder="Data/hora da Localização" matInput mask="d0/M0/0000"
               [value] = "getLocationTimestamp()" readonly> 
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>Placa</mat-label>
            <input matInput name="plate" placeholder="Placa" [value]="inMemoryDetailsAlert?.patrolTeam?.vehicle?.plate"
            readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>Usuário</mat-label>
            <input matInput name="user" placeholder="Usuário" [value]="inMemoryDetailsAlert?.userName? inMemoryDetailsAlert?.userName: ''"
            readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>Operação</mat-label>
            <input matInput name="operationType" placeholder="Operação"
              [value]="getOperationType()"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>ID Operação</mat-label>
            <input matInput name="operation" placeholder="ID Operação"
              [value]="getOperationIdentifier()"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
            <mat-label>ID Evento Vinculado</mat-label>
            <input matInput name="event" placeholder="ID Evento Vinculado"
              [value]="getEventIdentifier()"
              readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly w-100 mr-2" fxFlex *ngIf="inMemoryDetailsAlert.sourceType != 'SIMF'">
            <mat-label>Descrição</mat-label>
            <input matInput name="description" placeholder="Descrição" [value]="inMemoryDetailsAlert?.description && inMemoryDetailsAlert?.sourceType != 'SIMF'? inMemoryDetailsAlert?.description: ''"
                                                                  [matTooltip]="inMemoryDetailsAlert?.description && inMemoryDetailsAlert?.sourceType != 'SIMF'? inMemoryDetailsAlert?.description: ''"
            readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 mr-2" *ngIf="inMemoryDetailsAlert.sourceType == 'SIMF'">
            <mat-label>Dados SIMF</mat-label>
            <textarea matInput [value]="getSimfInfo(inMemoryDetailsAlert)" rows="20" readonly></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
